import React, { useState } from "react";
import axios from "axios";
import {Link, withRouter} from "react-router-dom";
import { Form, Input, notification } from "antd";
import { useDispatch } from "react-redux";

import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import {
  API_BASE_URL,
  ACCESS_TOKEN_NAME,
  KEY_USER_LOGIN,
  REFRESH_ACCESS_TOKEN,
  EXP,
} from "../../../constants/apiConstants";
import LoginForm from "../LoginForm/LoginForm";
import "./RegistrationForm.scss";
import { setAuthInfo } from "../../../redux/slice/auth/authSlice";
import LogoTesop from "../../../assets/images/logo_tesop.png";
import Logo from "../../svg/logo";

function RegistrationForm(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [open, isOpen] = useState(Boolean);

  const show = () => {
    isOpen(true);
  };

  // const handleSearchUsername = debounce((value) => {
  //   if (value) {
  //     axios
  //       .post(API_BASE_URL + "auth/check/username", {
  //         username: value,
  //       })
  //       .then((res) => {
  //         if (res?.data.data?.isExist == false) {
  //           form.setFields([
  //             {
  //               name: "username",
  //               warnings: [],
  //               errors: [],
  //             },
  //           ]);
  //         } else {
  //           form.setFields([
  //             {
  //               name: "username",
  //               errors: ["tài khoản này đã tồn tại!"],
  //             },
  //           ]);
  //         }
  //       })
  //       .catch((error) => {
  //         form.setFields([
  //           {
  //             name: "username",
  //             errors: ["Hệ thống không thể kiểm tra tài khoản này!"],
  //           },
  //         ]);
  //       });
  //   }
  // }, 600);
  // const handleSearch = debounce((value) => {
  //   if (value) {
  //     axios
  //       .post(API_BASE_URL + "auth/check/phone", {
  //         phoneNumber: form.getFieldValue("prefix") + value,
  //       })
  //       .then((res) => {
  //         if (res?.data.data?.isExist == false) {
  //           form.setFields([
  //             {
  //               name: "phoneNumber",
  //               warnings: [],
  //               errors: [],
  //             },
  //           ]);
  //         } else {
  //           form.setFields([
  //             {
  //               name: "phoneNumber",
  //               warnings: ["số điện thoại Khách hàng này đã tồn tại!"],
  //             },
  //           ]);
  //         }
  //       })
  //       .catch((error) => {
  //         form.setFields([
  //           {
  //             name: "phoneNumber",
  //             warnings: ["Hệ thống không thể kiểm tra số điện thoại này!"],
  //           },
  //         ]);
  //       });
  //   }
  // }, 600);

  const onFinish = (values) => {
    axios
      .post(API_BASE_URL + "auth/register", {
        username: values.username,
        password: values.password,
        email: values.email,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
      })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.token);
          localStorage.setItem(
            KEY_USER_LOGIN,
            JSON.stringify(response.data.data.user)
          );
          localStorage.setItem(
            REFRESH_ACCESS_TOKEN,
            response.data.data.refreshToken
          );
          localStorage.setItem(EXP, response.data.data.exp);
          dispatch(setAuthInfo(response.data.data.user));

          openNotificationWithIcon(
            notification,
            AlertType.success,
            "Đăng ký thành công"
          );
        }
      })
      .catch(function (error) {
        if (error.response?.status === 406) {
          openNotificationWithIcon(
            notification,
            AlertType.error,
            "Tài khoản đã tồn tại"
          );
        } else {
          openNotificationWithIcon(
            notification,
            AlertType.error,
            error?.response?.data?.message || "Lỗi hệ thống"
          );
        }
      });
  };

  return (
    <div>
      {open === false && (
        <div className="register-card">
          <div className="login-card-mobile">
              {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
                  <Logo />
              ) : (
                  <img src={LogoTesop} alt={""} width={150} />
              )}
          </div>
          <div className="form-register">
            <h2>Đăng ký</h2>
            <Form
              className={"form"}
              name="basic"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="col-wrap">
                <div className={"form-item"}>
                  <Form.Item
                    label={
                      <>
                        <div className="title-form">Tài khoản</div>
                      </>
                    }
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền tài khoản của bạn",
                      },
                      {
                        pattern: /^[a-zA-Z0-9]*$/,
                        message:
                          "Tài khoản không được chứa ký tự đặc biệt, dấu cách hoặc ký tự có dấu.",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                      // onChange={(value) => {
                      //   return handleSearchUsername(value.target.value);
                      // }}
                    />
                  </Form.Item>
                </div>
                <div className={"form-item"}>
                  <Form.Item
                    label={
                      <>
                        <div className="title-form">Mật khẩu</div>
                      </>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền mật khẩu của bạn",
                      },
                    ]}
                  >
                    <Input.Password
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                    />
                  </Form.Item>
                </div>
                <div className={"form-item"}>
                  <Form.Item
                    name="fullName"
                    label={
                      <>
                        <div className="title-form">Họ và tên</div>
                      </>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền họ tên của bạn",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                    />
                  </Form.Item>
                </div>
                {/* <div className={"form-item"}>
                                    <label className={"label-form"} htmlFor="confirmPassword"><LockOutlined/></label>
                                    <Form.Item
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        hasFeedback
                                        label={"Xác minh mật khẩu"}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng xác minh mật khẩu!',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Mật khẩu không hợp lệ!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password rootClassName={"form-input"} placeholder={"Xác minh mật khẩu"}/>
                                    </Form.Item>
                                </div> */}
                <div className={"form-item"}>
                  <Form.Item
                    name="phoneNumber"
                    label={
                      <>
                        <div className="title-form">Số điện thoại</div>
                      </>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng điền số điện thoại của bạn",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input-custom"}
                      placeholder={"Nhập vào"}
                      // onChange={(value) => {
                      //   return handleSearch(value.target.value);
                      // }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
                <div className={"form-item"}>
                  <Form.Item
                    name="email"
                    label={
                      <>
                        <div className="title-form">Email</div>
                      </>
                    }
                    rules={[
                      {
                        type: "email",
                        message: "Không đúng định dạng E-mail",
                      },
                      {
                        required: true,
                        message: "Vui lòng điền E-mail của bạn",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="footer-register">
                Hãy đảm bảo các thông tin bạn đã nhập là đúng, việc này sẽ giúp
                tài khoản của bạn được hỗ trợ tốt nhất về sau.
              </div>
              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <button className={"btn-register "} type={"submit"}>
                  Đăng ký tài khoản
                </button>
              </Form.Item>
            </Form>
            <div className="login">
              <div>Nếu bạn đã có tài khoản? </div>
                <Link to={"/login"}>
                    <div className="loginText">
                        Đăng nhập ngay
                    </div>
                </Link>
            </div>
          </div>
        </div>
      )}
     </div>
  );
}

export default withRouter(RegistrationForm);
