import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {Link, withRouter} from "react-router-dom";
import { Form, Input, notification } from "antd";

import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import RegistrationForm from "../Registration/RegistrationForm";
import "./LoginForm.scss";
import {
  API_BASE_URL,
  ACCESS_TOKEN_NAME,
  KEY_USER_LOGIN,
  REFRESH_ACCESS_TOKEN,
  EXP,
} from "../../../constants/apiConstants";
import { setAuthInfo } from "../../../redux/slice/auth/authSlice";
import LogoTesop from "../../../assets/images/logo_tesop.png";
import ForgotPassword from "../../forgot-password";
import Logo from "../../svg/logo";

function LoginForm() {
  const dispatch = useDispatch();
  const [open, setIsOpen] = useState(Boolean);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const show = () => {
    setIsOpen(true);
  };

  const showForgotPassword = () => {
    setIsShowPassword(true);
  };

  const onFinish = (values) => {
    axios
      .post(API_BASE_URL + "auth/login", {
        username: values.username,
        password: values.password,
      })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.token);
          localStorage.setItem(
            KEY_USER_LOGIN,
            JSON.stringify(response.data.data.user)
          );
          localStorage.setItem(
            REFRESH_ACCESS_TOKEN,
            response.data.data.refreshToken
          );
          localStorage.setItem(EXP, response.data.data.exp);

          dispatch(setAuthInfo(response.data.data.user));
          // if (response.data.data.user.is_admin) {
          //   redirectToDashboard();
          // } else {
          //   redirectToHome();
          // }
          openNotificationWithIcon(
            notification,
            AlertType.success,
            "Đăng nhập thành công"
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 406) {
          openNotificationWithIcon(
            notification,
            AlertType.error,
            error?.response?.data?.message || "Lỗi hệ thống"
          );
        }
      });
  };

  // const redirectToHome = () => {
  //   props.history.push("/home");
  // };
  //
  // const redirectToDashboard = () => {
  //   props.history.push("/admin/home");
  // };

  return (
    <>
      {open === false && isShowPassword === false && (
        <div className="login-card">
          <div className="login-card-mobile">
              {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
                  <Logo />
              ) : (
                  <img src={LogoTesop} alt={""} width={150} />
              )}
          </div>
          <div className="form-login">
            <h2>Đăng nhập</h2>
            <Form
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <div className={"form-item"}>
                <Form.Item
                  label={
                    <>
                      <div className="title-form ">Tài khoản</div>
                    </>
                  }
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền tài khoản của bạn",
                    },
                  ]}
                >
                  <Input
                    rootClassName={"form-input"}
                    placeholder={"Nhập vào"}
                  />
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <Form.Item
                  label={
                    <>
                      <div className="title-form">Mật khẩu</div>
                      <div
                        className="forgot-password"
                        onClick={() => showForgotPassword()}
                      >
                        Quên mật khẩu?
                      </div>
                    </>
                  }
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền mật khẩu của bạn",
                    },
                  ]}
                >
                  <Input.Password
                    rootClassName={"form-input"}
                    placeholder={"Nhập vào"}
                  />
                </Form.Item>
              </div>

              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <button className={"btn-login"} type={"submit"}>
                  Đăng nhập
                </button>
              </Form.Item>
              <div className="body-form">
                <div className="account">Bạn chưa có tài khoản?</div>
                  <Link  to="/register">
                      <div className="register">
                          Đăng ký ngay
                      </div>
                  </Link>
              </div>
            </Form>
          </div>
        </div>
      )}

      {isShowPassword === true && <ForgotPassword />}
    </>
  );
}

export default withRouter(LoginForm);
